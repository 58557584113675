import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const languages = ["fr", "en", "es"];
const namespaces = ["main", "forms", "video"];

/** Language files imports */
// TODO: Try to replace by https://github.com/i18next/i18next-fs-backend
// TODO: Find a clever way to automate this process , a simple loop won't work, see: https://github.com/facebook/react-native/issues/6391
const resources = {
  fr: {
    main: require("../../locales/fr/main.json"),
    forms: require("../../locales/fr/forms.json"),
    video: require("../../locales/fr/video.json"),
  },
  en: {
    main: require("../../locales/en/main.json"),
    forms: require("../../locales/en/forms.json"),
    video: require("../../locales/en/video.json"),
  },
  es: {
    main: require("../../locales/es/main.json"),
    forms: require("../../locales/es/forms.json"),
    video: require("../../locales/es/video.json"),
  },
};

const detectionOptions = {
  order: [
    "path",
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "subdomain",
  ],
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    supportedLngs: languages,
    resources,
    // have a common namespace used around the full app
    ns: namespaces,
    defaultNS: "main",

    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "ul", "li"],
    },

    detection: detectionOptions,
  });

export default i18n;
